/** @jsx jsx */
import {
  createStyles,
  Table,
  TableBody,
  Theme,
  withStyles,
  MuiThemeProvider,
} from '@material-ui/core'
import { ThemedCell, ThemedHeaderCell, ThemedTableRow } from 'components/common'
import { FC, ReactNode } from 'react'
import { jsx } from 'theme-ui'
import materialTheme from 'constants/theme/material'

interface IInfoTableRow {
  title: string
  value?: ReactNode
}

const TitleCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: '110px',
    },
  })
)(ThemedHeaderCell)

const InfoTableRow = ({ title, value }: IInfoTableRow) => {
  return (
    <ThemedTableRow>
      <TitleCell align="left">{title}</TitleCell>
      <ThemedCell align="left">{value || '—'}</ThemedCell>
    </ThemedTableRow>
  )
}

const InfoTableComponent: FC = ({ children }) => {
  return (
    <MuiThemeProvider theme={materialTheme}>
      <Table aria-label="simple table">
        <TableBody>{children}</TableBody>
      </Table>
    </MuiThemeProvider>
  )
}

const InfoTable = Object.assign(InfoTableComponent, { Row: InfoTableRow })

export { InfoTable }
